import * as filestack from "filestack-js";
import { z } from "zod";

export type Security = ReturnType<typeof filestack.getSecurity>;

export type FilestackFile = {
  filename: string;
  handle: string;
  size: number;
  type: string;
  url: string;
  mimetype: string;
};

/*
 * This function will only work server side since FILESTACK_SECRET is a private key
 */
export const sharedFilestackGetSecurity = function (secret: string) {
  const expiry = Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 1; // 1 day
  const security = filestack.getSecurity({ expiry }, secret);

  return { security, expiry };
};

export const sharedFilestackUtils = function (security: Security) {
  const downloadUrl = function (url?: string) {
    return `${url}?policy=${security.policy}&signature=${security.signature}`;
  };

  type ThumbnailParams = { height?: number; page?: number };

  const thumbnail = function (url: string, params: ThumbnailParams = {}) {
    const { height, page = 1 } = params;

    const baseUrl = "https://cdn.filestackcontent.com";

    if (!url.includes(baseUrl)) throw new Error(`Url ${url} must start with ${baseUrl}`);

    const filestackHandle = url.split(`${baseUrl}/`)[1];

    const securityPath = `security=policy:${security.policy},signature:${security.signature}`;

    const output = `output=format:jpg,page:${page},secure:true`;

    const fragments = [baseUrl, securityPath, output];

    if (height) {
      const resize = `resize=height:${height}`;

      fragments.push(resize);
    }

    fragments.push(filestackHandle);

    return fragments.join("/");
  };

  const thumbnailUrls = async function (filestackUrl: string, params: ThumbnailParams = {}) {
    let page = 1;
    let urls: string[] = [];

    // TODO: refacto
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    while (true) {
      const url = thumbnail(filestackUrl, { ...params, page });

      const response = await fetch(url, { method: "HEAD" });

      if (response.status !== 200) break;

      urls = [...urls, url];

      page++;
    }

    return urls;
  };

  return { downloadUrl, thumbnail, thumbnailUrls };
};

export const sharedFilestackOCR = function (security: Security, publicKey: string) {
  const { policy, signature } = security;

  const boundingBoxSchema = z.object({
    x: z.number(),
    y: z.number(),
  });

  const wordSchema = z.object({
    bounding_box: z.array(boundingBoxSchema),
    text: z.string(),
  });

  const lineSchema = z.object({
    bounding_box: z.array(boundingBoxSchema),
    text: z.string(),
    words: z.array(wordSchema),
  });

  const textAreaSchema = z.object({
    bounding_box: z.array(boundingBoxSchema),
    lines: z.array(lineSchema),
    text: z.string(),
  });

  const ocrResultSchema = z.object({
    document: z.object({
      text_areas: z.array(textAreaSchema),
    }),
    text: z.string(),
    text_area_percentage: z.number(),
  });

  const orc = async (url: string) => {
    const response = await fetch(url);

    const data = await response.json();

    const parsedData = ocrResultSchema.parse(data);

    return parsedData;
  };

  const fromFilestackHandle = (filestackHandle: string) => {
    const url = `https://cdn.filestackcontent.com/security=p:${policy},s:${signature}/ocr/${filestackHandle}`;

    return orc(url);
  };

  const fromExternalUrl = (externalUrl: string) => {
    const url = `https://cdn.filestackcontent.com/${publicKey}/security=p:${policy},s:${signature}/ocr/${externalUrl}`;

    return orc(url);
  };

  return { fromFilestackHandle, fromExternalUrl };
};

export const sharedFilestackConvertToPdf = function (security: Security, publicKey: string) {
  const client = filestack.init(publicKey, { security });

  const convertToPdf = async function (handleOrUrl: string) {
    const transformedUrl = client.transform(handleOrUrl, { output: { format: "pdf" } });

    const filestackFile = (await client.storeURL(transformedUrl)) as FilestackFile;

    return filestackFile;
  };

  return { convertToPdf };
};
