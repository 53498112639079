import type { Security } from "@asap/shared/src/utils/filestack";
import {
  sharedFilestackConvertToPdf,
  sharedFilestackGetSecurity,
  sharedFilestackOCR,
  sharedFilestackUtils,
} from "@asap/shared/src/utils/filestack";

/*
 * This function will only work server side since FILESTACK_SECRET is a private key
 */
export const filestackGetSecurity = function () {
  const config = useRuntimeConfig();

  return sharedFilestackGetSecurity(config.FILESTACK_SECRET);
};

export const filestackUtils = function (security: Security) {
  return sharedFilestackUtils(security);
};

export const filestackOCR = function (security: Security) {
  const config = useRuntimeConfig();

  return sharedFilestackOCR(security, config.public.FILESTACK_KEY);
};

export const filestackConvertToPdf = function (security: Security) {
  const config = useRuntimeConfig();

  return sharedFilestackConvertToPdf(security, config.public.FILESTACK_KEY);
};
